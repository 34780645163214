// import registerRootComponent from 'expo/build/launch/registerRootComponent';
import {registerRootComponent} from 'expo';
import {AppRegistry} from 'react-native';
import App from '../../App';


const name = 'tick'
AppRegistry.registerComponent(name, ()=> App);


registerRootComponent(App);
